import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;


const About: React.FunctionComponent = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>About</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
              MassCannData is a place for analysis and insights from the cannabis industry, with a tilt towards Massachusetts, USA. 
              The burgeoning recreational (aka “adult use”) cannabis market in the United States provides an amazing opportunity to 
              watch the rapid development of a historically illegal consumer product as it transitions out of the shadows and into the mainstream. 
              Luckily for us, many states have committed to a degree of data collection and transparency unheard of in any other industry. 
              There is open access to all kinds of data points from licensing to daily sales to product breakdowns, all ripe for a deep 
              dive where we will hopefully find some interesting tidbits.
              </p>
              <h2 align="center">Author</h2>
              <p>
              MCD is written by me, Alex. I am an entrepreneur and data nerd based in Massachusetts. I started this project in early 2019 
              after working in finance at a large cannabis cultivator where I came across a lot of interesting data I wanted to explore, 
              but I had nowhere to put it. I also get many questions on the industry from friends and colleagues, so this also serves as a 
              place to post the answers to common questions. I am not paid or otherwise compensated by any of the companies I may write about 
              here, this project is purely for my own edification and hopefully we all learn something.
              </p>
              <h2 align="center">Data Disclaimer</h2>
              <p>
              I am neither a lawyer nor a CPA and nothing written here should be construed as legal or financial advice. I make every effort to 
              ensure that the data and analysis I post here is accurate and up to date, however, I too make mistakes. That said, I will always 
              endeavor to correct any errors quickly as they are brought to my attention. 
              </p>
              <p>Contact: <i>info (at) masscanndata.com</i></p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
